/* AllRecipesPage.css */

.tag-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .recipe-display {
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
