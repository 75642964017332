/*
.recipe-card {
    border: 1px solid #ccc;
    margin-bottom: 16px;
    width: 350px;
    height: 400px;
    border-radius: 18px;
    overflow: hidden;
  }

  .card-content {
    padding: 15px;
  }
  
  .recipe-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: -1px;
  }
  
  .recipe-title {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .recipe-description {
    margin-bottom: 8px;
  }
  


  .title-expand-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  */

.expanded {
  text-align: left;
}

.image {
  width: 325px;
  height: 200px;
  overflow: hidden;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  object-fit: cover;
}

.image-default {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.recipe-title {
  font-size: 24px;
  text-align: center;
  padding: 1rem;
}

.actions {
  padding: 1rem;
  padding-top: 0;
  /* text-align: center; */
}

.card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin: 20px;
  width: 325px;
  height:fit-content;
}

.expand-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

